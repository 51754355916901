<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-container v-if="loading" style="width: 300px"></v-container>
    <v-container v-if="recordList.length">

      <v-row justify="center">
        <v-date-picker full-width v-model="date"
                       locale="ru-RU" first-day-of-week="1" color="teal lighten-1"
                       :events="functionEvents" event-color="red"
        ></v-date-picker>
      </v-row>

      <v-card-text class="elevation-3 rounded mt-5">
        <span class="red--text">Вы переносите запись:</span>
        <br>
        <span class="teal--text">
          <b>
            {{ recordList.find(r => r._id === id).date | date}}
            {{ recordList.find(r => r._id === id).time }}
          </b>
        </span>
        <br>
        <b>клиент:</b>
        {{ recordList.find(r => r._id === id).subscriber.firstName }}
        {{ recordList.find(r => r._id === id).subscriber.lastName }}
        <br>
        <b>услуга:</b>
        {{ recordList.find(r => r._id === id).category.title }}

      </v-card-text>

      <v-divider class="my-5"></v-divider>

      <RecordList
        :selectable="true"
        :winsLoading="winsLoading"
        :setRecord="setRecordFromChildComponent"
        :loadRecordList="loadRecordList"
        :dates="[date]"
        :recordList="recordList"
        :setWinsLoading="setWinsLoadingFromChildComponent"
      />

      <Wins
        :setWinsLoading="setWinsLoadingFromChildComponent"
        :date="date"
        :recordList="recordList"
        :winsLoading="winsLoading"
        :setTime="setTimeFromChildComponent"
        :loadRecordList="loadRecordList"
      />

      <v-divider class="my-5"></v-divider>

      <v-card-text v-if="record && time" class="red--text text-center">
        Выберите что-то одно
        <br>
        <b>ОКНО</b> или <b>ЗАПИСЬ</b>
      </v-card-text>

      <v-row class="my-5" justify="center">
        <v-btn @click="(record && !time ? exchangeHandler()
                        : !!time && !record ? openDialogToMoveHandler()
                        : null)"
               color="teal lighten-1"
               class="mx-1 white--text"
               small width="160" height="36"
               :disabled="(!record && !time) || (record && !!time)"
        >
          {{ (record && !time
          ? "Поменять местами"
          : time && !record
          ? "Перенести сюда"
          : "Ожидаю выбор") }}
        </v-btn>

        <CreateWinsButton
          :dates="[date]"
          :loadRecordList="loadRecordList"
          :many="false"
          :setWinsLoading="setWinsLoadingFromChildComponent"
        />
      </v-row>


      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">
            Выберите действие
          </v-card-title>

          <v-card-text>
            Оставить освободившееся окно доступным для других клиентов или удалить его?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="rejectHandler" color="error" text>Удалить</v-btn>

            <v-btn @click="createWindowAndRouteOut(dateToVacate, timeToVacate)" color="green darken-1" text>
              Оставить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogToMove" max-width="290">
        <v-card>
          <v-card-title class="headline">
            Выберите действие
          </v-card-title>

          <v-card-text>
            Оставить освободившееся окно доступным для других клиентов или удалить его?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="moveRecordHandler(true)" color="error" text>Удалить</v-btn>

            <v-btn @click="moveRecordHandler()" color="green darken-1" text>
              Оставить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
  import dateFilter from "../../filters/dateFilter"
  import Wins from "../../components/Wins"
  import CreateWinsButton from "../../components/CreateWinsButton"
  import RecordList from "../../components/RecordList";

  export default {
    name: "ExchangeRecords",
    components: {RecordList, CreateWinsButton, Wins},
    data: () => ({
      date: "",
      recordList: [],
      loading: true,
      record: undefined,
      time: undefined,
      winsLoading: false,
      dialog: false,
      dialogToMove: false,
      dateToVacate: "",
      timeToVacate: ""
    }),

    computed: {
      records() {
        return this.recordList.filter(record => !record.open)
      },
    },

    methods: {
      async loadRecordList() {
        let records = []
        const now = dateFilter(Date.now(), "ISO")
        this.winsLoading = true
        const json = await this.authFetch('/record/load-record-list-and-windows', {now})
        records = json.records
        if (!records.map(r => r._id).includes(this.id)) {
          const jsonToAdd = await this.authFetch('/record/load-record-on-id', {id: this.id})
          records.push(jsonToAdd.record)
        }
        records.sort(this.sortRecordListFunc)
        this.recordList = records
        this.winsLoading = false
      },

      initDate() {
        this.date = this.$route.query.date || dateFilter(Date.now(), "ISO")
      },

      functionEvents(date) {
        if (dateFilter(date, "ISO") <= dateFilter(Date.now(), "ISO")) {
          return false
        }

        const events = []
        this.recordList.forEach(record => {
          if (record.date === date) {
            if (!record.open) {
              events.push('#00f')
            } else {
              events.push('#b1fc92')
            }
          }
        })

        if (events.length) return events

        return false
      },

      async exchangeHandler() {
        const id1 = this.id
        const id2 = this.record._id
        const json = await this.authFetch('/record/exchange-records', {id1, id2})
        if (json.status === 200) {
          await this.$router.push("/calendar")
        }
      },

      async deleteWindow(date, time) {
        await this.authFetch('/record/delete-window', {time, date})
      },

      async createWindowHandler() {
        if (!this.timeModal.length || !this.date.length) {
          this.$store.commit("setServerMessage", "Сначала выберите время")
          return
        }
        this.modal = false
        this.winsLoading = true
        const json = await this.authFetch(
          '/record/init-record',
          {date: this.date, time: this.timeModal})
        if (json.status === 201) await this.loadRecordList()
        this.winsLoading = false
      },

      async createWindowAndRouteOut(date, time) {
        const json = await this.authFetch(
          '/record/init-record',
          {date: date, time: time},
          false
        )
        this.dialog = false
        if (json.status === 201) this.$store.commit("setServerMessage", "Освободившееся окно доступно для записи")
        await this.$router.push("/calendar")
      },

      openDialogToMoveHandler() {
        this.dialogToMove = true
      },

      async moveRecordHandler(deleteWindow = false) {
        const id1 = this.id
        const id2 = this.recordList.find(r => {
          return r.time === this.time && r.date === this.date
        })._id
        console.log(id1, id2)
        await this.authFetch('/record/exchange-records', {id1, id2, deleteWindow})
        this.$router.push('/calendar')
      },

      rejectHandler() {
        this.$store.commit("setServerMessage", "Окно удалено и больше недоступно")
        this.$router.push('/calendar')
      },

      setWinsLoadingFromChildComponent(bool) {
        this.winsLoading = bool
      },

      setTimeFromChildComponent(t) {
        this.time = t
      },

      setRecordFromChildComponent(r) {
        this.record = r
      },
    },

    async mounted() {
      if (!this.$route.query.id) {
        await this.$router.push("/calendar")
      }
      this.id = this.$route.query.id
      this.initDate()
      await this.loadRecordList()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>
